<template>
  <div class="relative" :class="divClass">
    <input
      ref="input"
      class="w-full rounded transition-all duration-200 px-2 pt-6 pr-4 pl-3 xs:pr-12 xs:pl-3 pb-4 text-sm placeholder-transparent focus:outline-none peer border"
      v-bind="attrs"
      :type="type"
      :value="modelWrapper"
      @input="updateInput"
      autocomplete="off"
      :class="
        valid
          ? 'hover:border-others-black focus:border-others-black border-others-grey3'
          : 'border-others-red'
      "
    />
    <label
      @click="selectInput"
      class="absolute text-others-grey3 cursor-text text-10 transition-all peer-placeholder-shown:top-6"
      :for="attrs.id"
      :class="
        smallText
          ? 'peer-placeholder-shown:text-xxs md:peer-placeholder-shown:text-xs peer-placeholder-shown:left-2 top-1 left-1 px-0'
          : 'peer-placeholder-shown:text-sm peer-placeholder-shown:left-4 top-2 left-3.5 px-1'
      "
      >{{ attrs.placeholder }}</label
    >

    <button
      type="button"
      aria-label="show-password"
      v-if="attrs.type == 'password' && !empty"
      @click.prevent="toggleShowPassword"
      class="absolute top-6 right-4"
    >
      <img
        v-if="!showPassword"
        src="@/assets/svg/icons/icons-no-visible.svg"
        alt="icono de no visible"
      />

      <img
        v-else
        src="@/assets/svg/icons/icons-visible.svg"
        alt="icono de visible"
      />
    </button>
    <button
      type="button"
      aria-label="action"
      v-if="attrs.type !== 'password' && !empty && action"
      :disabled="original"
      @click.prevent="handleAction()"
      class="absolute right-4"
      :class="[
        original ? 'opacity-50' : '',
        smallButton ? 'top-7' : 'top-[1.125rem]',
      ]"
    >
      {{ action }}
    </button>
  </div>
</template>

<script>
export default {
  inheritAttrs: false, // This is what disables attribute inheritance
  methods: {
    focus() {
      input.value.focus()
    },
  },
}
</script>

<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  divClass: {
    type: String,
    required: false,
  },
  valid: {
    type: Boolean,
    required: false,
    default: true,
  },
  action: {
    type: String,
    required: false,
  },
  original: {
    type: Boolean,
    required: false,
    default: true,
  },
  smallText: {
    type: Boolean,
    default: false,
  },
  smallButton: {
    type: Boolean,
    default: false,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue', 'action'])

const attrs = useAttrs()

const input = ref(null) // Template ref, points to the DOM input element, populated after rendered
const model = ref(props.modelValue)

const showPassword = ref(false)

defineExpose({ input })

onMounted(() => {
  if (props.autofocus) {
    input.value.focus()
  }
})

const empty = computed(() => {
  return (model.value = '')
})

const modelWrapper = computed({
  set: function (value) {
    model.value = value
  },
  get: function () {
    return props.modelValue
  },
})

const type = computed(() => {
  if (attrs.type === 'password') {
    return showPassword.value ? 'text' : 'password'
  } else {
    return attrs.type
  }
})

const selectInput = () => {
  // Trick for floating label placeholder overlay input
  input.value.focus()
}

const updateInput = (event) => {
  modelWrapper.value = event.target.value
  emit('update:modelValue', model.value)
}

const handleAction = () => {
  emit('action')
}

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value
}
</script>
